<template>
    
    <div class="tools-index-page">
        <div class="card-grid">
            <template v-for="item in list">
                <div class="item" :class="[item.size, item.key]" :key="item.route" @click="goTo(item)">
                    <img :src="item.img" alt="tools-icon" />
                    <div class="title">
                        <span>{{item.name}}</span>
                        <template v-if="item.size === 'size-1x1'">
                            <account-types :list="item.label" class="margin-l-10"></account-types>
                        </template>
                    </div>
                    <template v-if="item.size === 'size-1x2'">
                        <account-types :list="item.label" class="margin-t-10"></account-types>
                    </template>
                    <div class="description">{{item.desc}}</div>
                    <div class="label-flag new" v-if="item.new"></div>
                    <div class="label-flag free" v-if="item.free">{{item.free}}</div>
                    <div class="icon-flag" v-if="!item.permission">
                        <svg-icon name="ib-suo"></svg-icon>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>
<script>
import {mapState} from 'vuex';
import AccountTypes from '@/views/tools/components/AccountTypes';
import polishImg from '../../../static/images/tools/tools-polish-icon.png';
import alignImg from '../../../static/images/tools/tools-align-icon.png';
import termExtractionImg from '../../../static/images/tools/tools-termExtraction-icon.png';
import qaImg from '../../../static/images/tools/tools-qa-icon.png';
import detectorImg from '../../../static/images/tools/tools-detector-icon.png';
import videoTranslateImg from '../../../static/images/tools/tools-videoTranslate-icon.png';
import translationScoreImg from '../../../static/images/tools/tools-translationScore-icon.png';
import gptTranslateImg from '../../../static/images/tools/tools-gptTranslate-icon.png';
export default {
    components: {
        AccountTypes,
    },
    data(){
        return{
            list: [],
        }
    },
    computed:{
         ...mapState({
			userInfo: state => state.user.userData,
         }) 
    },
    created(){
        this.initList();
    },

    methods:{
        initList(){
            this.list = [
                {key: 'polish', route: '/tools/polish/index', permission: this.getPermission('polish'), size: 'size-1x2', img: polishImg, name: 'AI 润色', desc: '基于生成式AI，你的随身母语润色专家', label: [], new: false, free:'', },
                {key: 'translationScore', route: '/tools/translationScore/index', permission: this.getPermission('translationScore'), size: 'size-1x1', img: translationScoreImg, name: 'AI 翻译评分', desc: '机翻译文智能评分，Al为你推荐参考翻译模型', label: [], new: false, free:'', },
                {key: 'detector', route: '/tools/detector/index', permission: this.getPermission('detector'), size: 'size-1x1', img: detectorImg, name: '机翻检测', desc: '智能标记机翻痕迹，有效识别译文质量风险', label: ['pro'], new: false, free:'', },
                {key: 'align', route: '/tools/align/index', permission: this.getPermission('align'), size: 'size-1x1', img: alignImg, name: '语料对齐', desc: '业内领先的对齐算法，拖拽式对齐体验，AI让语料对齐更简单', label: [], new: false, free:'', },
                // {key: 'gptTranslate', route: '/tools/gptTranslate/index', permission: this.getPermission('gptTranslate'), size: 'size-1x2', img: gptTranslateImg, name: '大模型智能体翻译', desc: '集合多个智能体的本地化流程协作，更专业更精准', label: [], new: true, free:'', },
                {key: 'videoTranslate', route: '/tools/videoTranslate/index', permission: this.getPermission('videoTranslate'), size: 'size-1x2', img: videoTranslateImg, name: 'AI 译配', desc: '一键翻译和配音，瞬间切换语言的AI魔法', label: [], new: false, free:'免费试用开启', },
                {key: 'qa', route: '/tools/qa/index', permission: this.getPermission('qa'), size: 'size-1x1', img: qaImg, name: 'QA 检查', desc: '不止基于规则的QA，更基于语义理解的智能检查', label: ['erp'], new: false, free:'', },
                {key: 'termExtraction', route: '/tools/termExtraction/index', permission: this.getPermission('termExtraction'), size: 'size-1x1', img: termExtractionImg, name: '术语提取', desc: '智能提取批量文件术语，快速修改即可制作术语库', label: [], new: false, free:'', },
            ];
        },
        getPermission(key){
            let rst = false;
            switch (key) {
                case 'translationScore':
                    rst = true;
                    break;
                case 'gptTranslate':
                    rst = true //this.$checkPermission('tools:gptTranslateRouter') && ['pro-trial', 'pro', 'pro-internal', 'internal', 'enterprise', 'STANDARD', 'TRIAL', 'ULTIMATE'].indexOf(this.userInfo.accountType) > -1;
                    break;
                case 'videoTranslate': 
                    rst = true //this.$checkPermission('tools:videoTranslateRouter') && ['pro-trial', 'pro', 'pro-internal', 'internal', 'enterprise', 'STANDARD', 'TRIAL', 'ULTIMATE'].indexOf(this.userInfo.accountType) > -1;
                    break;
                case 'polish': 
                    rst = this.$checkPermission('tools:polishRouter');
                    break;
                case 'align':
                    rst = this.$checkPermission('tools:alignmentRouter');
                    break;
                case 'detector':
                    rst = this.$checkPermission('tools:detectorRouter') && ['pro-trial', 'pro', 'pro-internal', 'internal', 'enterprise', 'STANDARD', 'TRIAL', 'ULTIMATE'].indexOf(this.userInfo.accountType) > -1;
                    break;
                case 'termExtraction':
                    rst = true //this.$checkPermission('tools:extractionRouter') && ['pro-trial', 'pro', 'pro-internal', 'internal', 'enterprise', 'STANDARD', 'TRIAL', 'ULTIMATE'].indexOf(this.userInfo.accountType) > -1;
                    break;
                case 'qa':
                    rst = this.$checkPermission('tools:checkRouter') && ['pro-internal', 'internal', 'enterprise', 'STANDARD', 'TRIAL', 'ULTIMATE'].indexOf(this.userInfo.accountType) > -1;
                    break;
            }
            return rst;
        },
        goTo(item){
            if(item.permission) {
                this.$router.push(item.route);
                // window.open(item.route, '_blank');
            }else {
                this.$message.error('请升级到对应的版本后使用。')
            }
        }
    },
    mounted(){

    }
}
</script>
<style lang="scss" scoped>
.tools-index-page {
    height: 80%;
    padding: 32px 0;
    background-image: url(../../../static/images/tools/tools-bg.png);
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center top;
    .card-grid {
        width: 1190px;
        margin: auto;
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(auto-fill,minmax(380px,1fr));
        .item {
            min-height: 135px;
            border-radius: 16px;
            position: relative;
            padding: 22px 26px;
            background: #FFFFFF;
            box-sizing: border-box;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;
            &:hover {
                box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.13);
            }
            &.size-1x1 {
                grid-column: span 1;
                grid-row: span 1;
            }
            &.size-1x2 {
                grid-column: span 1;
                grid-row: span 2;
                align-items: center;
                padding: 22px 40px;
                img {
                    width: 72px;
                    height: 72px;
                }
                .title {
                    font-size: 20px;
                    line-height: 24px;
                    margin-top: 22px;
                }
                .description {
                    font-size: 12px;
                    font-size: 14px;
                    line-height: 20px;
                    color: #7a7a7a;
                    margin-top: 20px;
                    text-align: center;
                }
            }
            img {
                width: 38px;
                height: 38px;
            }
            .title {
                font-weight: 600;
                font-size: 15px;
                margin-top: 15px;
                display: flex;
                align-items: center;
            }
            .description {
                font-size: 12px;
                color: #7a7a7a;
                margin-top: 11px;
            }
            &.translationScore {
                border: 1px solid #FEF1EF;
                background: linear-gradient(180deg, #FDEEEC 0%, #FFF 100%);
            }
            &.videoTranslate {
                background: linear-gradient(180deg, #CFF9F4 0%, #FFF 100%);
                border: 1px solid #DFF6ED;
            }
            &.gptTranslate {
                border: 1px solid #DFF6ED;
                background: linear-gradient(180deg, #CFF9F4 0%, #FFF 100%);
            }
            &.polish {
                background: linear-gradient(180deg, #DBDAFF 0%, #FFF 100%);
                border: 1px solid #F1F1FA;
            }
            &.align {
                background: linear-gradient(180deg, rgba(205, 218, 251, 0.7) 0%, #FFFFFF 100%);
                border: 1px solid #DFE8FF;
            }
            &.detector {
                background: linear-gradient(180deg, #DBDAFF 0%, #FFFFFF 100%);
                border: 1px solid #F1F1FA;
            }
            &.termExtraction {
                background: linear-gradient(180deg, #CFF9F4 0%, #FFF 100%);
                border: 1px solid #DFF6ED;
            }
            &.qa {
                background: linear-gradient(180deg, #FDEEEC 0%, #FFFFFF 100%);
                border: 1px solid #FEF1EF;
            }
            .label-flag{
                // width: 53px;
                padding: 0 10px;
                height: 24px;
                border-radius: 0 16px 0 10px;
                position: absolute;
                top: 0;
                right: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                &.new {
                    background-color: #FAE2E2;
                    &::after{
                        content: "NEW";
                        color: #FF5455;
                        font-weight: 500;
                        font-size: 12px;
                    }
                }
                &.free {
                    background-color: #FAE2E2;
                    color: #FF5455;
                    font-weight: 500;
                    font-size: 12px;
                }
                &.pro {
                    background-color: #FFF4E7;
                    &::after{
                        content: "PRO";
                        color: #E98C02;
                        font-weight: 500;
                        font-size: 12px;
                    }
                }
                &.erp {
                    background-color: #DFD7F8;
                    &::after{
                        content: "企业版";
                        color: #AA84DA;
                        font-weight: 500;
                        font-size: 12px;
                    }
                }
            }
            .icon-flag {
                font-size: 16px;
                color: #999999;
                position: absolute;
                top: 30px;
                right: 30px;
            }
        }
    }
    .tools-grid{
        margin-top: 60px;
        display: grid;
        grid-gap: 30px;
        grid-template-columns: repeat(auto-fill,minmax(300px,1fr));
        .item{
            padding: 40px 30px;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            background: #FEFEFE;
            border-radius: 20px;
            cursor: pointer;
            &:hover {
                box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.13);
            }
            img {
                width: 96px;
                height: 96px;
            }
            .label-new{
                width: 71px;
                height: 32px;
                background-color: #FAE2E2;
                border-radius: 0 20px 0 20px;
                position: absolute;
                top: 0;
                right: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                &::after{
                    content: "new";
                    color: #FF5455;
                    font-weight: 500;
                    font-size: 24px;
                }
            }
        }
    }
}
</style>
